import React from "react";
import { Link } from "react-router-dom";
import { useProducts } from "../api/getProducts";
import { Product as ProductType } from "../types";

type Props = {

};

export const Products: React.FC<Props> = () => {
    const productQuery = useProducts();

    if (productQuery.isLoading) {
        return <>Loading...</>;
    }

    if (!productQuery.data) return null;
    return <>
        <div className="hero">
            <h1>Products</h1>
        </div>
        <div className="content">
            <ProductList products={productQuery.data} />
        </div>
    </>
};

const ProductList: React.FC<{ products: ProductType[] }> = ({ products }) => {
    return <div className="section products" style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridTemplateRows: "repeat(2, 1fr)", columnGap: "1rem", rowGap: "1rem" }}>
        {products.map((product, key) => <ProductView key={key} product={product} />)}
    </div>
};


const ProductView: React.FC<{ key: number, product: ProductType }> = ({ product: { name, description, link, links } }) => {
    return <div className="card">
        <div className="card-header" >
            <a href={link} style={{ color: "var(--primary-color)"}}> 
                <h3>{name}</h3>
            </a>
        </div>
        <div className="card-body">
            <p>{description}</p>
        </div>
        <div className="card-footer" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div>
                {links?.documentation && <a href={links.documentation} target="_blank" className="button" title="Documentation"><i className="fa-solid fa-book" /> Docs</a>}
                {links?.releaseLatest && <a href={links.releaseLatest} target="_blank" className="button" title="Release"><i className="fa-solid fa-code-compare" /> Release</a>}
                {links?.accelerators && <Link to={links.accelerators} className="button" title="Accelerators"><i className="fa-solid fa-gauge-high" /> Accelerators</Link>}
            </div>
        </div>
    </div>
}
