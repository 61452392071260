import React, { Dispatch, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Loading } from "../../../components/Loading";
import { useLicense } from "../../../providers/license";
import { useAccelerators } from "../api/getAccelerators";
import { Accelerator, AcceleratorFilterOptions } from "../types";

export const Accelerators: React.FC = () => {
    const [search, setSearch] = useState({});

    const acceleratorQuery = useAccelerators({}, { ...search });

    return <>
        <div className="hero">
            <h1> Xill4 accelerators</h1>
            <Link to="/" className="button" title="Home"><i className="fa-solid fa-house" /> Home</Link>
        </div>
        <div className="accelerators-page" >
            < AcceleratorFilter search={search} setSearch={setSearch} />
            <div>
                <div className="description card " >
                    <p> Xill4 accelerators are a pre-created set of flows that help you kickstart a migration.
                        For more information about accelerators and how to install them, see the
                        <a href="https://docs.xill.io/xill4/connectors-and-accelerators/" target="_blank" rel="noopener noreferrer" style={{ textAlign: 'center', fontWeight: 'bold', color: 'darkblue' }}> Accelerators documentation</a>. 
                    </p>
                    <div style={{ textAlign: "left" }}>
                        <h3><p>There are three types of accelerators:</p></h3>
                        <ul style={{ fontWeight: "normal" }}>
                            <li>Source connectors - Source connectors read data from a repository.</li>
                            <li>Target connectors - Target connectors load data into a repository.</li>
                            <li>Framework - Framework accelerators are utility accelerators used to help with common tasks like certain transformations, reports, and more.</li>
                        </ul>
                    </div>
                </div>
                {acceleratorQuery.isLoading && <Loading />}
                {acceleratorQuery.data && <AcceleratorList accelerators={acceleratorQuery.data} />}
            </div>
        </div>

    </>
};


const AcceleratorFilter: React.FC<{ search: AcceleratorFilterOptions, setSearch: Dispatch<AcceleratorFilterOptions> }> = ({ search, setSearch }) => {
    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        setSearch({ ...search, searchTerm: Object.fromEntries(formData)['search'] as string })
    };

    return <div className="card filter">
        <div >
            <form
                onSubmit={handleSearch}
            >
                <input
                    type="text"
                    placeholder="Search..."
                    name="search"
                />
            </form>
        </div>
        <select
            value={search.selectedAuthor}
            onChange={(e) => setSearch({ ...search, selectedAuthor: e.target.value })}
            className="select"
        >
            <option value="">All Authors</option>
            <option value="Xillio">Xillio</option>
            <option value="Community">Community</option>
        </select>

        <select
            value={search.selectedType}
            onChange={(e) => setSearch({ ...search, selectedType: e.target.value })}
            className="select"
        >
            <option value="">All Types</option>
            <option value="source">Source</option>
            <option value="target">Target</option>
            <option value="framework">Framework</option>
        </select>
    </div>
}


const AcceleratorList: React.FC<{ accelerators: Accelerator[] }> = ({ accelerators }) => {
    return <div className="section accelerators" style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridTemplateRows: "repeat(2, 1fr)", columnGap: "1rem", rowGap: "1rem" }}>
        {accelerators.map((accelerator, key) => <AcceleratorView key={key} accelerator={accelerator} />)}
    </div>
};


const AcceleratorView: React.FC<{ key: number, accelerator: Accelerator }> = ({ accelerator: { id, name, version, type, xill4_version, description, author } }) => {
    const license = useLicense();

    const downloadLatest = async () => {
        const response = await fetch(`/api/accelerators/${id}/download`, {
            headers: {
                'Authorization': 'License ' + license
            }
        });

        if (!response.ok) {
            alert("Error downloading accelerator");
            return;
        }

        const blob = await response.blob();

        let filename = response.headers.get("Content-Disposition")?.split("filename=")[1];
        filename = filename?.replace(/"/g, "");

        if (!filename) filename = `${id}.zip`;

        const file = window.URL.createObjectURL(blob);
        const element = document.createElement('a');
        element.href = file;
        element.download = filename;
        document.body.appendChild(element);
        element.click();
        element.remove();
    }


    return <div className="card">
        <div className="card-header" >
            <h3>{name}</h3>
        </div>
        <div className="card-body">
            <p>{description}</p>
            {author && <p>Author: {author}</p>}
            {version && <p>Accelerator version: {version}</p>}
            {xill4_version && ( "Xillio" === author 
                ? <p>Supported by Xill4 version {xill4_version} and higher</p>
                : <p>Created with Xill4 version {xill4_version}. <i>Support in other versions might be limited.</i></p>
            )}
        </div>
        <div className="card-footer" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div>
                <a
                    href={author === 'Community' ? `./accelerators/${id}/readme` : `https://docs.xill.io/xill4/accelerators/${type}/${getDocsPath(id)}`}
                    target="_blank"
                    className="button"
                    title="Documentation"
                    rel="noreferrer"
                >
                    <i className="fa-solid fa-book" /> Docs
                </a>
                <a onClick={() => downloadLatest()} target="_blank" className="button" style={{ cursor: 'pointer' }} title="Documentation"><i className="fa-solid fa-download" /> Download</a>
            </div>
        </div>
    </div>
}


//The id's are not always the same as the documentation path, so we need to convert them. We think it has slight more business value to have nicer links than nicer code.
function getDocsPath(id: string) {
    const name = id.split("-").filter(
        (word) => word !== "source" && word !== "target" && word !== "framework")
      .join("-");
    switch (name) {
      case "box-com":
        return "box-dot-com";
      case "file":
        return "file-system";
      case "filenethybrid":
        return "filenet-hybrid";
      case "livelinkdb":
        return "livelink";
      case "opentextedocsdb":
        return "opentext-edocs-db";
      case "opentextcsdb":
        return "opentext-cs-db";
      case "purview-pii-scanner":
        return "purview";
      case "sp-onpremise":
        return "sharepoint-on-premise";
      case "spo":
          return "sharepoint-online";
      default:
          return name;
    }
  }