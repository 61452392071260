import { Readme, Accelerators, LicenseForm } from "../features/accelerators/routes";
import { Product } from "../features/products/routes/Product";
import { Products } from "../features/products/routes/Products";

export const publicRoutes = [
    {
        path: "/login",
        element: <h1>Login</h1>,
    },
    {
        path: "/products",
        element: <Products />,
    },
    {
        path: "/products/:productId",
        element: <Product />, 
    },
    {
        path: "/accelerators/:acceleratorId/readme",
        element : <Readme />,
    },
    {
        path: "/accelerators",
        element: <Accelerators />,
    },
    {
        path: "/license",
        element: <LicenseForm />,
    },
    {
        path: "/support",
        element: <h1>Support</h1>,
    }
]
