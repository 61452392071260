import React from "react";
import { Link, useParams } from "react-router-dom";
import { useProduct } from "../api/getProduct";
import { ContentLayout } from "../../../components/layout/ContentLayout";
import { Product as ProductType } from "../types";

type Props = {

};

export const Product: React.FC<Props> = () => {
    const { productId } = useParams();
    const productQuery = useProduct(productId!);

    if (productQuery.isLoading) {
        return <>Loading...</>;
    }

    if (!productQuery.data) return null;
    return <ContentLayout title={productQuery.data.name}>
        <ProductView {...productQuery.data} />
    </ContentLayout>;
};

const ProductView: React.FC<ProductType> = ({ name, description, links }) => {
    return <>
        <div>
            <p>{description}</p>
        </div>
        <div>
            <div>
                {links?.documentation && <a href={links.documentation} target="_blank" className="button" title="Documentation"><i className="fa-solid fa-book" /> Docs</a>}
                {links?.releaseLatest && <a href={links.releaseLatest} target="_blank" className="button" title="Release"><i className="fa-solid fa-code-compare" /> Release</a>}
                {links?.accelerators && <Link to={links.accelerators} className="button" title="Accelerators"><i className="fa-solid fa-gauge-high" /> Accelerators</Link>}
            </div>
        </div>
    </>;
};
