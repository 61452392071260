import React, { useEffect } from "react";


export type License = string;
type LicenseContext = {
    license: null | License;
    updateLicense: (license: null | License) => void;
}

// @ts-ignore will configure in provider
const context = React.createContext<LicenseContext>(null);

export function useLicense() {
    const _context = React.useContext(context);
    if (_context === undefined) {
        throw new Error('useLicense must be used within a LicenseProvider');
    }
    return _context.license;
}

export function LicenseProvider(props: { children: React.ReactNode }) {

    const [license, setLicense] = React.useState<null | License>(localStorage.getItem('license') ?? null);

    useEffect(() => {
        const license = localStorage.getItem('license');
        if (license) setLicense(license);
    }, []);

    const updateLicense = (license: null | License) => {
        if (license === null) localStorage.removeItem('license');
        else localStorage.setItem('license', license);
        setLicense(license);
    };

    return React.createElement(context.Provider, {
        value: {
            license,
            updateLicense
        }
    }, props.children);
}

export const LicenseContext = context;
