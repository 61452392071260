type Props = {
    title: string;
    children: React.ReactNode;
}

export const ContentLayout: React.FC<Props> = ({title, children}) => {
    return <>
        <div className="hero">
            <h1>{title}</h1>
        </div>
        <div className="content">
            {children}
        </div>
    </>
}