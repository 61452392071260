import { Shortcut } from "../types";

export const shortcuts: Shortcut[] = [
    {
        title: "Products",
        subtitle: "Read documentation, see release notes and access downloads to our products",
        icon: "fa-boxes-stacked",
        linkTo: "/products"
    },
    {
        title: "E-Learning",
        subtitle: "Follow courses and become Xillio certified",
        icon: "fa-graduation-cap",
        linkTo: "https://xillio.talentlms.com/"
    },
    {
        title: "News & Blogs",
        subtitle: "Read all about the latest new from Xillio",
        icon: "fa-newspaper",
        linkTo: "https://www.xillio.com/blog"
    },
    {

        title: "Support",
        subtitle: "Need help? Don't hesitate to contact support",
        icon: "fa-headset",
        linkTo: "mailto:support@xillio.com?subject=Request for support"
    },
    {
        title: "Q&A",
        subtitle: "Get expert feedback with problems while using our software",
        icon: "fa-brands fa-stack-overflow",
        linkTo: "https://stackoverflowteams.com/c/xillio/questions"
    },
    {
        title: "Partner Training", 
        subtitle: "Find specialized partner training material",
        icon: "fa-handshake",
        linkTo: "https://learn-xillio.talentlms.com/"
    },
    {
        title: "Accelerators", 
        subtitle: "Get your migration started by downloading accelerators.",
        icon: "fa-bolt",
        linkTo: "/accelerators",
        hidden: true
    },

]
