import { Shortcut, Topic } from "../types";
import { useQuery } from "react-query";
import { Product } from "../../products/types";
import { products } from "../../products/data/products";
import { shortcuts } from "../data/shortcuts";

export const search = (query: string, itemCount = 4): Promise<Topic[]> => {
    return new Promise(resolve => setTimeout(() => {
        const filtered = topics.map(t => [t, rank(query, t)] as [Topic, number])
            .sort((a, b) => b[1] - a[1])
            .filter(([t, rank]) => rank > 0)
            .map(([t, _]) => t)
            .slice(0, itemCount);
        resolve(filtered);
    }, Math.random() * 250 + 250));
}

export const useSearch = (query: string) => {
    return useQuery(
        ['search', query],
        () => search(query),
        { enabled: !!query }
    );
};

const topics: Topic[] = [
    ...products.map(fromProduct),
    ...shortcuts.map(fromShortcut),
];

function fromProduct(product: Product): Topic {
    return {
        name: product.name,
        description: product.description,
        linkTo: `/products/${product.slug}`,
        keywords: product.keywords,
        type: 'product'
    };
}

function fromShortcut(shortcut: Shortcut): Topic {
    return {
        name: shortcut.title,
        description: shortcut.subtitle,
        linkTo: shortcut.linkTo,
        keywords: [],
        type: 'shortcut'
    };
}

function rank(search: string, topic: Topic) {
    const s = search.toLowerCase();
    let _rank = 0;

    _rank += { product: 2, shortcut: 1 }[topic.type];
    _rank += topic.name.toLowerCase().includes(s) ? 5 : 0
    _rank += topic.keywords.map(k => k.toLowerCase()).reduce((acc, k) => acc + (k.includes(s) ? 2 : 0), 0);
    _rank += topic.description.toLowerCase().includes(s) ? 1 : 0;

    return _rank;
}