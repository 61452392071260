import { useRoutes } from "react-router-dom";
import { Landing } from "../features/misc/routes/Landing";
import { publicRoutes } from "./public";

export const AppRoutes = () => {
    const auth = false;

    const common = [{
        path: "/",
        element: <Landing />,
    }];
    
    const routes = auth ? [] : publicRoutes;
    const element = useRoutes([...routes, ...common]);
    return <>{element}</>;
}