import { useState, KeyboardEvent } from "react";
import { useSearch } from "../api/search";
import { Topic as TopicType } from "../types";

export const SearchBar = () => {
    const [search, setSearch] = useState("");
    const searchQuery = useSearch(search);

    const updateSearch = (e: KeyboardEvent) => {
        setSearch((e.target as HTMLInputElement).value);
    }

    return <div className='search' style={{ position: "relative" }}>
        <input placeholder="Search for a specific topic..." onKeyUp={updateSearch} />
        {searchQuery.isLoading
            ? <Loading />
            : (search.length > 0
                ? searchQuery.data && searchQuery.data.length > 0
                    ? <Topics topics={searchQuery.data} />
                    : <NoResults />
                : <Favorites />
            )}
    </div>
}

const Loading = () => <div>Loading...</div>;

const Topics: React.FC<{ topics: TopicType[] }> = ({ topics }) => {
    return <ul style={{ position: "absolute", width: "100%", top: '4rem', padding: '0.5rem', boxShadow: '1px 1px 10px rgba(0,0,0,0.2)', background: 'white' }}>{topics.map(Topic)}</ul>
}

const Topic: React.FC<TopicType> = (topic) => {
    return <li><a href={topic.linkTo}><h6>{topic.name}</h6><p>{topic.description}</p></a></li>;
}

const Favorites = () => {
    return <div className="suggestions">
        <span>Favorite topics:</span>
        <a href="/products/xill4">Xill 4</a>
        <a href="/products/link-redirector">Xillio Link Redirector</a>
        <a href="https://xillio.talentlms.com/">E-Learning</a>
    </div>;
}

const NoResults = () => {
    return <div>
        <span>We couldn't find any matches for your search, please try:</span>
        <a href="mailto:support@xillio.com?subject=Request for support">Contacting support</a>
        <a href="https://stackoverflowteams.com/c/xillio/questions">Asking our experts</a>
    </div>
}
