import { Accelerator } from "../types";
import { useQuery } from "react-query";
import { useLicense } from "../../../providers/license";

export const getAccelerator = async (id: string, license: null | string): Promise<undefined | Accelerator> => {
    const response = await fetch('/api/accelerators/' + id, {
        headers: {
            'Authorization': 'License ' + license,
        }
    });
    if (!response.ok) {
        throw new Error(response.status + ' ' + response.statusText);
    }
    return await response.json();
}

async function bundleAccelerator(id: string, version: string, license: null | string) {

    const [accelerator] = await Promise.all([
        getAccelerator(id, license),
    ]);
    if (!accelerator) return undefined;
    return { ...accelerator };
}

export const useAccelerator = (acceleratorId: string, version: string = 'latest') => {
    const license = useLicense();
    return useQuery(
        ['accelerators', acceleratorId, version],
        () => bundleAccelerator(acceleratorId, version, license),
        { enabled: !!acceleratorId }
    );
};
