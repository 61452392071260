import React from "react";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate } from "react-router-dom";
import { LicenseContext } from "../../../providers/license";
import { Accelerator as _Accelerator } from "./Accelerator";
import { Readme as _Readme } from "./Readme";
import { Accelerators as _Accelerators } from "./Accelerators";

const LicenseBoundary: React.FC<PropsWithChildren> = (props) => {
    const context = React.useContext(LicenseContext);

    const resetLicense = () => {
        context.updateLicense(null);
        return <Navigate to={'/license'} />;
    }

    return <ErrorBoundary FallbackComponent={resetLicense}>
        { props.children }
    </ErrorBoundary>
};


export const Accelerator = () => <LicenseBoundary><_Accelerator/></LicenseBoundary>;
export const Accelerators = () => <LicenseBoundary><_Accelerators/></LicenseBoundary>;
//Readme has prop id which is a string
export const Readme = () => <LicenseBoundary><_Readme /></LicenseBoundary>;
export * from "./LicenseForm";
