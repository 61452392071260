import { Product } from "../types";
import { products } from "../data/products";
import { useQuery } from "react-query";

export const getProduct = (id: string): Promise<undefined | Product> => {
    const product = products.find((product) => product.slug === id) as Product;
    return Promise.resolve(product);
}

export const useProduct = (productId: string) => {
    return useQuery(
        ['products', productId], 
        () => getProduct(productId),
        { enabled: !!productId }
    );
};