import React, { HTMLProps, PropsWithChildren } from "react";

type CardProps = {
    title: string;
    subtitle?: string;
    icon?: string;
    linkTo?: string;
}
export const Card: React.FC<PropsWithChildren<HTMLProps<HTMLDivElement> & CardProps>> = ({ children, icon, title, subtitle, linkTo, ...props }) => {
    return <div className="card shortcut" {...props}>
        <div className="card-header">
            { icon && <i className={"fa-solid " + icon} /> }
            <h3>{title}</h3>
            { subtitle && <span>{subtitle}</span>}
        </div>
        {React.Children.map(children, (child) => <div className="card-body">{child}</div>)}
        {linkTo && <div className="card-action">
            <a href={linkTo}>View more</a>
        </div>
        }
    </div>
}
