import React, { ChangeEventHandler, FormEventHandler } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout";
import { LicenseContext } from "../../../providers/license";

export const LicenseForm = () => {
    const { updateLicense } = React.useContext(LicenseContext);
    const [error, setError] = React.useState<string | null>(null);

    const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const regexp = /\S{5}-\S{5}-\S{5}-\S{5}/;
        const value = event.target.value;

        if (value.length === 0) setError(null);
        else if (!regexp.test(value)) setError('Invalid license key');
        else setError(null);
    };

    const onSubmit: FormEventHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        // @ts-ignore
        const key = event.target.key.value;
        updateLicense(key);
        window.location.href = "/accelerators";
    };

    return <ContentLayout title={"License key is required"}>
        <h1>Enter your license key</h1>
        <div className="content">
            <form onSubmit={onSubmit}>
                <div>
                    <input name="key" type="test" placeholder="License key" onChange={onChange} />
                    <button type="submit">Submit</button>
                </div>
                {error && <div className="error" style={{ color: 'red' }}>{error}</div>}
            </form>
        </div>
    </ContentLayout>;
};


